
.carrossel_container {
    height: var(--number-carrossel-default-height);
    /* width: 260px; */
    padding: 17px 0 20px 0;
    display: inline-flex;
    flex-direction: column;
}

.carrossel_wrap {
    opacity: 0;
}

.carrossel_overflow {
    width: 200px;
    padding: 17px 0 20px 0;
    display: inline-flex;
    flex-direction: column;
    /* padding-left: 63px; */
    /* padding-bottom: 25px; */
    /* overflow: hidden; */
}

.carrossel_container .slider .slider-frame {
    overflow: visible!important;
}

.carrossel_slide {
    width: 120px;
    height: 170px;
    margin-top: 15px;
    /* margin-left: 15px; */
    opacity: 1;
    display: inline-flex;
    background-color: var(--color-secondary);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    box-shadow: 3px 3px 3px 0px rgba(0,0,0,0.3);
    -webkit-transition-property: width height margin opacity box-shadow;
    -webkit-transition-duration: 0.5s;
    -webkit-transition-timing-function: ease-in-out;
    transition-property: width height margin opacity box-shadow;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
}

.slide-visible .carrossel_slide {
    width: 200px;
    height: 320px;
    margin-top: 0px;
    margin-left: 0px;
    opacity: 1;
    box-shadow: 8px 8px 0px 0px rgba(0,0,0,0.2);
    -webkit-transition-property: width height margin opacity box-shadow;
    -webkit-transition-duration: 0.5s;
    -webkit-transition-timing-function: ease-in-out;
    transition-property: width height margin opacity box-shadow;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
    
}
li.slider-slide.slide-visible{
    
    z-index: 1;
} 

 @media (max-width: 420px) 
{
    .carrossel_wrap{
        width: 90px;
    }
}

li:focus{
    outline:none;
}

.boat_wrap{
    position: fixed;
    left:0;
    top:0;
    width: 100vw;
    height: 100vh;
    background-color: var(--color-background);
    vertical-align: middle;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.boat_animation {
    width: 250px;
}

.boat_none{
    display: none
}