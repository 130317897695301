.inputWrapper {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.inputWrapper input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color-text-primary);
  opacity: 1; /* Firefox */
}

.inputWrapper input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--color-text-primary);
}

.inputWrapper input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--color-text-primary);
}

.inputWrapper input {
  cursor: pointer;
  text-align: center;
  font-size: 12px;
  color: var(--color-text-primary);
  background-color: #ffffff00;
  width: 250px;
  margin-top: 4px;
  margin-bottom: 20px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-color: var(--color-text-primary);
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  text-align: left;
  font-size: 14px;
  padding-left: 20px;
  box-sizing: border-box;
}

.auth_login_button_other {
  font-family: "Intro Regular";
  cursor: pointer;
  font-size: 16px;
  color: var(--color-text-primary);
  padding: 5px 10px 5px 5px;
  background-color: #ffffff00;
  border-width: 0px;
  text-decoration: underline;
}

.auth-page-content-h2 {
  margin: 0px;
  padding: 10px;
  font-size: 1.3em !important;
}

.auth_pad_button_other {
  /* scale: 1; */
}

.auth_login_message {
  margin-top: 23px;
  margin-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 13px;
  color: var(--color-text-primary);
}

.auth_login_error {
  font-size: 14px;
  color: var(--color-primary);
  margin-top: -15px;
  align-self: flex-start;
}

.auth_login_container form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;
}

.auth_method_list::-webkit-scrollbar {
  width: 20px; /* width of the entire scrollbar */
}

.auth_method_list::-webkit-scrollbar-track {
  background: #fff; /* color of the tracking area */
  border-radius: 20px; /* roundness of the scroll thumb */
}

.auth_method_list::-webkit-scrollbar-thumb {
  background-color: var(--color-secondary); /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}

.auth_method_list_loading {
  height: 250px;
}

.auth_page_primary_button {
  font-family: "Intro Black Regular";
  cursor: pointer;
  font-size: 20px;
  color: var(--color-text-primary);
  background-color: var(--color-primary);
  margin: 30px 0 30px 0;
  padding: 12px 10px 8px 10px;
  width: 50%;
  max-width: 230px;
  border: 0;
  border-radius: 6px;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 20%);
}

.auth_page_secondary_button {
  font-family: "Intro Regular";
  cursor: pointer;
  font-size: 18px;
  text-decoration: underline;
  color: var(--color-text-primary);
  background-color: transparent;
  border: none;
  padding-bottom: 10px;
}

.inputWrapper {
  max-width: 400px;
  display: flex;
  flex-direction: column;
}

.auth_login_button_login {
  font-family: "Intro Bold";
  font-size: 18px;
  margin-top: 20px;
  padding: 0px;
  width: 196px;
  height: 50px;
  cursor: pointer;
  background-color: var(--color-primary);
  color: var(--color-text-primary);
  border: 0;
  border-radius: 7px;
  text-decoration: none;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.remove-image {
  background-image: none;
}

.auth-page-content-main {
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
  background-image: url(../images/land-screen-eulalia.png), url(../images/bg-app-login.png);
  background-position: center, top center;
  background-size: contain, auto;
  background-repeat: no-repeat, no-repeat;
}

.auth-page-content-secondary {
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
  background-image: url(../images/bg-app-login.png);
  background-position: top center;
  background-size: auto;
  background-repeat: no-repeat;
}

.setOpacity {
  opacity: 1;
}