.auth_title {
  font-family: "Intro Bold";
  color: var(--color-text-primary);
  padding-left: 10%;
  padding-right: 10%;
  font-size: 18px;
  line-height: 22px;
}

.auth_space {
  width: 300px;
  height: 400px;
  background-color: #00000000;
}