.preloader_container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.preloader_animate {
    width: 100%;
    height: 100%;
    background-image: url('./../../assets/images/eulalia-animated-splash-screen.gif');
    background-size: contain;
    background-position: center 40px;
    background-repeat: no-repeat;
}

.preloader_animate img {
}

@media screen and (max-width: 450px) and (orientation: portrait) {
    .preloader_animate img {
    }
}

@media screen and (max-width: 914px) and (orientation: landscape) {
    .preloader_animate img {
    }
}

.subtitle {
    font-size: 20px;
    position: absolute;
    bottom: 40px;
}