* {
  box-sizing: border-box;
}

.wrapper {
  display: flex;
  flex-flow: column nowrap;

  width: 100%;
  height: 100%;

  padding: 2vh 3vw;

  background-color: #f5f5f5;
}

.content .title {
  font-size: 1.5rem;
  color: #333;
}

.content h2 {
  font-size: 1.35rem;
  color: #3f3f3f;
}

.content p {
  text-align: left;
  font-size: 1.2rem;
  color: #4a4a4a;
  line-height: 1.6rem;
}

.wrapper .home-button {
  font-family: "Intro Bold";
  width: fit-content;

  margin: 0 auto;
  padding: 10px 20px;

  border: none;
  border-radius: 10px;

  box-shadow: 0 0 5px #00000066;
  background-color: var(--color-background);
  color: var(--color-gray);
  font-size: 1.2rem;
  font-weight: 700;

  cursor: pointer;
}

@media (min-width: 768px) {
  .content p {
    text-align: justify;
  }
}
