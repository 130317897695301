
.others_title_container {
    padding-top: 25px;
    padding-bottom: 17px;
    opacity: 0;
}

.others_title_container p {
    font-family: "Intro Regular";
    color: var(--color-text-tertiary);
    font-size: 25px;
    margin-block-start: 0px;
    margin-block-end: 0px;
}