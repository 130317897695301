.error404_container {
    display: flex;
    align-items: center;
    min-height: 100vh;
    flex-direction: column;
    justify-content: center;
    background-color: var(--color-background-gray-404);
    background-image: url('./../../assets/images/bg-app-error.png');
    background-size: cover;
    background-position: center;
}

.error404_container img {
    width: 300px;
}