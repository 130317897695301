

.book_detail_buttons_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.book_detail_container {
    opacity: 0;
    padding-top: 12px;
    padding-bottom: 17px;
}

.book_detail_title {
    font-family: "Intro Regular";
    font-size: 17px;
    color: var(--color-text-primary);
    margin-block-start: 5px;
    margin-block-end: 10px;
    padding-left: 25px;
    padding-right: 25px;
    white-space: pre-wrap;
}

.book_detail_infos {
    font-family: "Intro Regular";
    font-size: 15px;
    color: var(--color-text-primary);
    margin-block-start: 5px;
    margin-block-end: 10px;
    padding-left: 25px;
    padding-right: 25px;
    white-space: pre-wrap;
}

.book_detail_container button {
    font-family: "Intro Bold";
    cursor: pointer;
    font-size: 18px;
    color: var(--color-text-primary);
    background-color: var(--color-primary);
    margin-top: 4px;
    margin-left: 4px;
    margin-right: 4px;
    margin-bottom: 18px;
    padding-top: 10px;
    padding-bottom: 6px;
    padding-left: 25px;
    padding-right: 25px;
    border-color: var(--color-primary);
    border-radius: 3px;
    border-width: 1px;
    border-style: solid;
    width: 200px;
}

.book_detail_container :disabled {
    opacity: 0.5;
}

.book_detail_link {
    font-family: "Intro Regular";
    font-size: 13px;
    color: var(--color-text-primary);
}
