.main_logo {
    position: relative;
    padding-top: 50px;
    /* padding-bottom: 20px; */
    width: 100%;
    max-width: 450px;
    z-index: 1001;
}

.logo_none {
    position: absolute;
    display: none;
}