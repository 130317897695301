.auth_method_login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.auth_method_title {
  font-family: "Intro Regular";
  color: var(--color-text-primary);
  padding-left: 20%;
  padding-right: 20%;
  font-size: 26px;
  margin-top: 20px;
}

.auth_method_list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  margin-top: 5px;
  margin-bottom: 20px;
  max-width: 300px;
  width: 100%;
  padding: 10px 0 0 0;
  height: 400px;
  background-color: var(--color-white);
  border-radius: 10px;
  scroll-snap-type: y mandatory;
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

.auth_method_list_letter {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.auth_method_list_letter h2 {
  font-family: "Intro Regular";
  color: var(--color-text-primary);
  background-color: var(--color-secondary);
  margin-left: 110px;
  margin-right: 110px;
  padding-top: 10px;
  padding-bottom: 6px;
}

.auth_method_item {
  display: flex;
  width: 100%;
  min-height: 20px;
  justify-content: space-between;
  cursor: pointer;
}

.auth_method_item_infos {
  width: 100%;
  text-align: left;
}

.auth_method_list_separator {
  margin-bottom: 17px;
  border: .1px solid var(--color-gray);
}

.auth_method_item_infos span {
  color: var(--color-text-secondary);
  font-size: 17px;
  font-family: "Intro Regular";
  padding-left: 20px;
}