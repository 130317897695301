.section_background {
    position: absolute;
    width: 100%;
    -webkit-transition-property: height top;
    -webkit-transition-duration: 0.5s;
    -webkit-transition-timing-function: ease-in-out;
    transition-property: height top;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
}

.section_frontground {
    opacity: 0.999;
}

.section_backgrounds {
    background-image: url('./../../assets/images/bg-app-02.png'), url('./../../assets/images/bg-pattern-center.png'), url('./../../assets/images/bg-app-01.png');
    background-position: bottom, center, top;
    background-size: 1000px, auto, 1000px;
    background-repeat: no-repeat, repeat-y, no-repeat;
}

.section_bgColor {
    
}
