.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
  overflow: hidden;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}
